import { render, staticRenderFns } from "./ExfoliantsTemplate.vue?vue&type=template&id=91eaef10&"
import script from "./ExfoliantsTemplate.vue?vue&type=script&lang=js&"
export * from "./ExfoliantsTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports