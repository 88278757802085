<template>
  <div class="skin-exfoliants q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['toleratesExfoliantActives'])">
      <single-answer-question
        title="label.myExfoliantTolerance"
        :value="toleratesExfoliantActives"
        :options="$options.skinExfoliantsOptions"
        hint="info.exfoliantTolerance"
        @input="onFieldChange('toleratesExfoliantActives', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <div v-if="isUseOtherExfoliantsVisible">
        <question-with-error-wrap
          id="other-exfoliants"
          class="q-mt24 q-mt32-md"
          :error="getFieldsError(['useOtherExfoliants'])"
        >
          <single-answer-question
            title="label.useOtherExfoliants"
            :value="useOtherExfoliants"
            :options="$options.useOtherExfoliantsOptions"
            yes-no
            two-columns
            hint="info.useOtherExfoliants"
            @input="onFieldChange('useOtherExfoliants', $event)"
          />
        </question-with-error-wrap>
      </div>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { TOLERATES_EXFOLIANT } from '@/modules/questionnaire/api/constants';
import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

const SKIN_EXFOLIANTS_OPTIONS = [
  { value: TOLERATES_EXFOLIANT.YES, text: 'toleratesExfoliants.yes' },
  { value: TOLERATES_EXFOLIANT.NO, text: 'toleratesExfoliants.no' },
  { value: TOLERATES_EXFOLIANT.UNSURE, text: 'toleratesExfoliants.unsure' }
];

export default {
  name: 'ExfoliantsTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  skinExfoliantsOptions: SKIN_EXFOLIANTS_OPTIONS,
  useOtherExfoliantsOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    toleratesExfoliantActives: {
      type: String,
      default: ''
    },
    useOtherExfoliants: {
      type: String,
      default: ''
    },
    isUseOtherExfoliantsVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
