import ExfoliantsTemplate from '@/modules/questionnaire/components/steps/common/medical-background/exfoliants/ExfoliantsTemplate';

import { BOOLEAN } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/Exfoliants/ExfoliantsTemplate',
  component: ExfoliantsTemplate
};

const createStory = props => () => ({
  components: { ExfoliantsTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><exfoliants-template v-bind="$options.storyProps"/></div>'
});

export const YesAnswer = createStory({
  toleratesExfoliantActives: BOOLEAN.YES,
  isUseOtherExfoliantsVisible: true,
  useOtherExfoliants: BOOLEAN.YES
});

export const NoAnswer = createStory({
  toleratesExfoliantActives: BOOLEAN.NO
});
